<template>
    <nav class="navbar navbar-fixed-top">
        <div class="container-fluid">
            <div class="navbar-right">
                <div class="navbar-btn">
                    <button type="button" class="btn btn-toggle-offcanvas" v-on:click="layoutFullwidth()"><i class="lnr lnr-menu fa fa-bars"></i></button>
                </div>
                <!-- <form id="navbar-search" class="navbar-form search-form">
                    <input value="" class="form-control" placeholder="Search here..." type="text">
                    <button type="button" class="btn btn-default"><i class="icon-magnifier"></i></button>
                </form> -->

                <div id="navbar-menu">
                    <ul class="nav navbar-nav">
                        <!-- <li>
                            <router-link to="/admin/uikit/file-documents" class="icon-menu d-none d-sm-block d-md-none d-lg-block"><i class="fa fa-folder-open-o"></i></router-link>
                        </li>
                        <li>
                            <router-link to="/admin/uikit/app-calendar" class="icon-menu d-none d-sm-block d-md-none d-lg-block"><i class="icon-calendar"></i></router-link>
                        </li>
                        <li>
                            <router-link to="/admin/uikit/app-chat" class="icon-menu d-none d-sm-block"><i class="icon-bubbles"></i></router-link>
                        </li>
                        <li>
                            <router-link to="/admin/uikit/app-inbox" class="icon-menu d-none d-sm-block"><i class="icon-envelope"></i><span class="notification-number">8</span></router-link>
                        </li>
                        <li class="dropdown" v-bind:class="{ 'show': notificationMenu}"  @click="notificationMenu = !notificationMenu" v-click-outside="notificationOutside">
                            <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">
                                <i class="icon-bell"></i>
                                <span class="notification-number">23</span>
                            </a>
                            <ul class="dropdown-menu notifications" v-bind:class="{ 'show': notificationMenu}" :style="notificationMenu ? 'top: 100%;' : 'top: 80%;'">
                                <li class="header"><strong>You have 4 new Notifications</strong></li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <div class="media">
                                            <div class="media-left">
                                                <i class="icon-info text-warning"></i>
                                            </div>
                                            <div class="media-body">
                                                <p class="text">Campaign <strong>Holiday Sale</strong> is nearly reach budget limit.</p>
                                                <span class="timestamp">10:00 AM Today</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <div class="media">
                                            <div class="media-left">
                                                <i class="icon-like text-success"></i>
                                            </div>
                                            <div class="media-body">
                                                <p class="text">Your New Campaign <strong>Holiday Sale</strong> is approved.</p>
                                                <span class="timestamp">11:30 AM Today</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                 <li>
                                    <a href="javascript:void(0);">
                                        <div class="media">
                                            <div class="media-left">
                                                <i class="icon-pie-chart text-info"></i>
                                            </div>
                                            <div class="media-body">
                                                <p class="text">Website visits from Twitter is 27% higher than last week.</p>
                                                <span class="timestamp">04:00 PM Today</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <div class="media">
                                            <div class="media-left">
                                                <i class="icon-info text-danger"></i>
                                            </div>
                                            <div class="media-body">
                                                <p class="text">Error on website analytics configurations</p>
                                                <span class="timestamp">Yesterday</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="footer"><a href="javascript:void(0);" class="more">See all notifications</a></li>
                            </ul>
                        </li> -->
                        <!-- <li class="dropdown" v-bind:class="{ 'show': dropActive}"  @click="dropActive = !dropActive" v-click-outside="outside">
                            <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown"><i class="icon-equalizer"></i></a>
                            <ul class="dropdown-menu user-menu menu-icon" v-bind:class="{ 'show': dropActive}" :style="dropActive ? 'top: 100%;' : 'top: 80%;'">
                                <li class="menu-heading">ACCOUNT SETTINGS</li>
                                <li><a href="javascript:void(0);"><i class="icon-note"></i> <span>Basic</span></a></li>
                                <li><a href="javascript:void(0);"><i class="icon-equalizer"></i> <span>Preferences</span></a></li>
                                <li><a href="javascript:void(0);"><i class="icon-lock"></i> <span>Privacy</span></a></li>
                                <li><a href="javascript:void(0);"><i class="icon-bell"></i> <span>Notifications</span></a></li>
                                <li><a href="javascript:void(0);" v-b-modal.business_modal><i class="icon-equalizer"></i> <span>Business</span></a></li>
                                <li class="menu-heading">BILLING</li>
                                <li><a href="javascript:void(0);"><i class="icon-credit-card"></i> <span>Payments</span></a></li>
                                <li><a href="javascript:void(0);"><i class="icon-printer"></i> <span>Invoices</span></a></li>
                                <li><a href="javascript:void(0);"><i class="icon-refresh"></i> <span>Renewals</span></a></li>
                            </ul>
                        </li> -->
                        <li>
                            <a href="javascript:void(0);" class="icon-menu" @click="logout"><i class="icon-power"></i></a>
                            <!-- <router-link to="/page-login" class="icon-menu"><i class="icon-power"></i></router-link> -->
                        </li>
                    </ul>
                    <b-modal id="business_modal" title="Add Primary Contact" hide-footer centered scrollable no-stacking
                        header-class="align-items-center">
                        <template #modal-header>
                            <h5 class="modal-title">Switch Accounts</h5>
                            <b-button pill variant="primary" v-b-modal.headre_add_business><i class="fa fa-plus"></i>
                                Add</b-button>
                        </template>
                        <div class="modal-area">
                            <ul class="list-unstyled mb-0 transfers-list switch-account-list">
                                <template v-for="(busines,index) in businesses">
                                    <li class="d-flex align-items-center" :class="{'active': busines.isActive}"
                                        :key="index" v-on:click="onSelect(index)">
                                        <div class="left">
                                            <i aria-hidden="true" class="fa" v-if="!busines.img">{{ busines.title |
                                            short_hand }}</i>
                                            <img class="fa" :src="busines.img" v-if="busines.img" />
                                        </div>
                                        <div class="right">
                                            <strong class="font-16 text-uppercase">{{ busines.title }}</strong>
                                            <p>{{ busines.subTitle }}</p>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </b-modal>
                    <header-business-model></header-business-model>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
import { EventBus } from '@/plugins/event-bus.js';
export default {
    name: 'HeaderComponent',
    components: {
        'header-business-model': () => import('@/components/admin/business/BusinessModal.vue')
    },
    methods: {
        outside: function () {
            this.dropActive = false
        },
        notificationOutside: function () {
            this.notificationMenu = false
        },
        toggleSideMenu: function () {
            document.body.classList.toggle('offcanvas-active')
        },
        onSelect: function (index) {
            this.businesses.forEach(function (business, i) {
                if (i === index) {
                    business.isActive = true
                } else {
                    business.isActive = false
                }
            });
        },
        layoutFullwidth() {
            this.show = !this.show;
            let sitebody = document.body;
            if(window.innerWidth < 1200){
               this.toggleSideMenu()
            }else{
                this.show
                ? sitebody.classList.add("layout-fullwidth")
                : sitebody.classList.remove("layout-fullwidth");
                EventBus.$emit('sidebar-mini',this.show);
            }
            sessionStorage.setItem("sidebarmini", this.show);
        },
        logout() {
            sessionStorage.removeItem("jwtToken")
            this.$toast.success('Logout Successfull...!!', { position: 'top-right' })
            this.$router.push('/page-login');
        },
    }, data() {
        return {
            show:false,
            dropActive: false,
            notificationMenu: false,
            toggleSidebarClass: false,
            businesses: [
                {
                    img: require(`@/assets/img/logo-icon.svg`),
                    title: 'PAYINC GROUP LIMITED',
                    subTitle: 'Business',
                    isActive: false
                },
                {
                    img: '',
                    title: 'GUDEND STRATEGIES LIMITED',
                    subTitle: 'Business',
                    isActive: true
                },
                {
                    img: '',
                    title: 'PAYANGEL FINANCIAL SOLUTIONS',
                    subTitle: 'Business',
                    isActive: false
                },
                {
                    img: '',
                    title: 'PAYINC TECHNOLOGIES LIMITED',
                    subTitle: 'Business',
                    isActive: false
                }
            ]
        }
    }, directives: {
        'click-outside': {
            bind: function (el, binding) {
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler)
            },
        }
    }, filters: {
        short_hand(companyName) {
            let words = companyName.split(" ")
            let short_hand = words[0][0] + words[words.length - 1][0]
            return short_hand // <-- The return value as Per logic
        }
    },
    mounted(){
        this.show = sessionStorage.getItem("sidebarmini") == 'true' ? !this.show : this.show;
    }
}
</script>

