<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Middleware Transaction List</h2>
                    </div>
                    <!-- Transaction Listing-->
                    <div class="body pt-0">
                        <!-- Filters/Buttons -->
                        <div class="chart-top-action d-lg-flex justify-content-end py-3">
                            <!-- <div class="p-2 mr-auto">
                                <VMdDateRangePicker class="pull-right" opens='left' v-model="dateRange" @change="getSelectedDateRange($event)" :presets="presets" :autoApply="false"></VMdDateRangePicker>
                            </div> -->
                            <!-- SearchBox Filters -->
                            <!-- <div class="d-flex align-items-center flex-column flex-md-row-reverse justify-content-center justify-content-md-start mb-3 payment-dropdown"> -->
                                <!-- <b-dropdown v-if="false" class="mr-0 mw-" id="download" text="Download" size="lg" variant="outline-primary btn-mw">
                                    <template #button-content>
                                        Download <i class="fa fa-download"></i>
                                    </template>
                                    <b-dropdown-item href="">Export as PDF</b-dropdown-item>
                                    <b-dropdown-item href="">Export as Excel</b-dropdown-item>
                                    <b-dropdown-item href="">Export as CSV</b-dropdown-item>
                                </b-dropdown> -->
                                <div class="mr-0 mr-lg-3 action-top-search">
                                    <div class="input-group">
                                        <input type="text" class="form-control" v-model="searchText"
                                            placeholder="Enter Keywords" aria-label="Enter Keywords"
                                            aria-describedby="search-transactions" v-on:keyup.enter="refreshTransactionTable">
                                        <div class="input-group-append" @click="refreshTransactionTable()">
                                            <span class="input-group-text" id="search-mail"><i
                                                    class="icon-magnifier"></i></span>
                                        </div>
                                        <div class="input-group-append" @click="clearSearch()" v-if="this.searchText">
                                            <span class="input-group-text" id="search-mail"><i class="icon-close"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Toggle Filter Button -->
                                <div class="fliter-main mr-auto mt-3 mt-lg-0">
                                    <b-button class="fliter-btn btn-lg btn-mw"
                                        :variant="isVisibleFilter ? 'primary' : 'outline-primary'"
                                        @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter"
                                            aria-hidden="true"></i></b-button>
                                </div>
                            <!-- </div> -->
                            <!-- Toggle Filter Collapse -->
                            <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                <div class="d-flex justify-content-between p-3">
                                    <!-- <div class="form-group datepicker">
                                        <b-input-group>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">From</span>
                                                <span class="input-group-text"
                                                    @click="$refs.birthdate.showCalendar()"><i
                                                        class="icon-calendar"></i></span>
                                                <datepicker class="form-control" ref="birthdate" v-model="search.from"
                                                    :bootstrap-styling="false" placeholder="" :typeable="true">
                                                </datepicker>
                                            </div>
                                        </b-input-group>
                                    </div>
                                    <div class="form-group datepicker">
                                        <b-input-group>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">To</span>
                                                <span class="input-group-text"
                                                    @click="$refs.birthdate1.showCalendar()"><i
                                                        class="icon-calendar"></i></span>
                                                <datepicker class="form-control" ref="birthdate1" v-model="search.to"
                                                    :bootstrap-styling="false" placeholder="" :typeable="true">
                                                </datepicker>
                                            </div>
                                        </b-input-group>
                                    </div>
                                    <div class="form-group form-select single-multiselect">
                                        <span class="float-label">
                                            <multiselect
                                                placeholder="Select Country"
                                                v-model="search.country"
                                                :show-labels="false" 
                                                :options="countryOption" 
                                                label="name"
                                                :searchable="true">
                                            </multiselect> 
                                        </span>
                                        <div class="line"></div>
                                    </div>
                                    <div class="form-group form-select single-multiselect">
                                        <span class="float-label">
                                            <multiselect placeholder="Transaction Status" v-model="search.tstatus"
                                                :show-labels="false" :options="transactionStatus" :searchable="true">
                                            </multiselect>
                                        </span>
                                        <div class="line"></div>
                                    </div> -->
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="search.fname" placeholder="First Name" />
                                        <!-- <div class="line"></div> -->
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="search.mname" placeholder="Middle Name" />
                                        <!-- <div class="line"></div> -->
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="search.lname" placeholder="Last Name" />
                                        <!-- <div class="line"></div> -->
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="search.mref" placeholder="Merchant Ref. No." />
                                        <!-- <div class="line"></div> -->
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between p-3">
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="search.email" placeholder="Email" />
                                        <!-- <div class="line"></div> -->
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="search.mobile" placeholder="Mobile" />
                                        <!-- <div class="line"></div> -->
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="search.prefno" placeholder="Provider Ref. No." />
                                        <!-- <div class="line"></div> -->
                                    </div>
                                    
                                    <div class="form-group">
                                        <button class="btn btn-primary h-100" @click="refreshTransactionTable()">Apply</button>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-outline-danger h-100" @click="handleresetFilter()">Cancel/Clear</button>
                                    </div>
                                </div>
                            </b-collapse>
                            <div class="payment-dropdown d-flex flex-lg-row-reverse flex-column ml-lg-3 ml-0 mt-3 mt-lg-0">
                                <div class="country-dropdown curancy-dropdown mb-0">
                                    <div class="form-group form-select multiselect-grp mb-0">
                                        <span class="float-label">
                                        <label for="roles">Select Tradename</label>
                                        <multiselect
                                            placeholder="Select Tradename"
                                            v-model="tradename" 
                                            :show-labels="false" 
                                            :options="tradename_options" 
                                            :searchable="false"
                                            label="name"
                                            @select="setprovider">
                                        </multiselect>  
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- All Transaction List -->
                        <b-table responsive ref="transactionTable" table-class="mb-0 table-custom border transaction-list-table"
                            head-variant="light" :items="getTransactionList" :fields="fields" :current-page="currentPage"
                            :per-page="perPage" :sort-by="sortBy" :sort-desc="sortDesc"
                            :sort-direction="sortDirection" :emptyText="'No data found.'" show-empty>

                            <template #head()="{ label, field: { key } }">
                                {{ label }}
                                <!-- Custom icons -->
                                <div>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </div>
                            </template>
                            <template #cell(srccountry)="data">
                                <div class="align-items-center d-flex justify-content-center">
                                    <span :class="data.value.flagClass"></span>
                                </div>
                            </template>
                            <template #cell(sender)="data">
                                <strong>
                                    <!-- <router-link class="font-color" to="/customer/edit" target="_blank"> -->
                                        {{ data.value.name.substring(0, 19)}}<span v-if="data.value.name.length > 19">...</span>
                                    <!-- </router-link> -->
                                </strong>
                            </template>
                            <template #cell(benificiary)="data">
                                <strong>
                                    <!-- <router-link class="font-color" to="/customer/edit" target="_blank"> -->
                                        {{ data.value.name.substring(0, 19)}}<span v-if="data.value.name.length > 19">...</span>
                                    <!-- </router-link> -->
                                </strong>
                            </template>
                            <template #cell(custRefno)="data">
                                <div class="align-items-center d-flex">
                                    <span>
                                        <!-- <i :class="data.value.class" class="mr-1"></i> -->
                                        <!-- <router-link class="font-color" to="/transaction/edit"> -->
                                            {{ data.value }}
                                        <!-- </router-link> -->
                                    </span>
                                </div>
                            </template>
                            <template #cell(datetime)="data">
                                <strong>{{ data.value.date }}</strong>
                                <small class="text-grey d-block">{{ data.value.time }}</small>
                            </template>
                            <template #cell(accno)="data">
                                <div class="align-items-center d-flex">
                                    <span>
                                        <i :class="data.value.type" class="mr-1"></i>
                                        <strong>{{ data.value.acc.substring(0, 19)}}<span v-if="data.value.acc.length > 19">...</span></strong><br /><span class="text-grey d-block">{{
                                                data.value.no
                                        }}</span></span>
                                </div>
                            </template>

                            <template #cell(destamount)="data">
                                <div class="d-flex align-items-start justify-content-center">
                                    <p class="mb-0 text-right">
                                        <span class="d-block">{{ data.value.destamount }}</span>
                                    </p>
                                </div>
                            </template>
                            <template #cell(status)="data">
                                <p class="function-badge badge-group-table mb-0 text-center">
                                    <span class="badge ml-0 d-block" :class="data.value.class">{{ data.value.name
                                    }}</span>
                                </p>
                            </template>
                            <template #cell(action)="data">
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item @click="showTransactionDetail(data.item)" >View Details</b-dropdown-item>
                                    <!-- <b-dropdown-item>Download Receipt</b-dropdown-item>
                                    <b-dropdown-item>Send Email</b-dropdown-item> -->
                                </b-dropdown>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"
                                        size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    hide-goto-end-buttons hide-ellipsis prev-text="Previous page" next-text="Next page"
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go to Page</span>
                                    </div>
                                    <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="show-details" size="lg" class="transaction-lg-modal" title="Transaction Details" hide-footer no-stacking no-close-on-backdrop>
            <div v-if="detailsstatus" class="modal-area">
                <div class="transaction-details-body">
                    <h6 class="text-danger">{{errorMsg}}</h6>
                </div>
            </div>
            <div  v-if="!detailsstatus" class="modal-area">
                <div class="row">
                    <div class="col-md-6">
                        <ul class="list-unstyled mb-0 mr-2">
                            <li class="border-bottom py-3">
                                <p class="mb-0 font-16 d-flex align-items-center">
                                <span class="font-weight-bold w-30">Sender Name:</span>
                                <span class="ml-2">{{ transactionDetail.sendername }}</span>
                                </p>
                            </li>
                            <li class="border-bottom py-3">
                                <p class="mb-0 font-16 d-flex align-items-center">
                                <span class="font-weight-bold w-30">Merchant Ref. No.:</span>
                                <span class="ml-2">{{ transactionDetail.refno }}</span>
                                </p>
                            </li>
                            <li class="border-bottom py-3">
                                <p class="mb-0 font-16 d-flex align-items-center">
                                <span class="font-weight-bold w-30">Source Amount:</span>
                                <span class="ml-2">{{ transactionDetail.srcamount }}</span>
                                </p>
                            </li>
                            <li class="border-bottom py-3">
                                <p class="mb-0 font-16 d-flex align-items-center">
                                <span class="font-weight-bold w-30">Transaction Status:</span>
                                <span class="ml-2">{{ transactionDetail.transStatus }}</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul class="list-unstyled mb-0">
                            <li class="border-bottom py-3">
                                <p class="mb-0 font-16 d-flex align-items-center">
                                <span class="font-weight-bold w-30">Beneficiary Name:</span>
                                <span class="ml-2">{{ transactionDetail.beneficiaryname }}</span>
                                </p>
                            </li>
                            <li class="border-bottom py-3">
                                <p class="mb-0 font-16 d-flex align-items-center">
                                <span class="font-weight-bold w-30">Account No.:</span>
                                <span class="ml-2">{{transactionDetail.accName}} / {{ transactionDetail.accNumber }}</span>
                                </p>
                            </li>
                            <li class="border-bottom py-3">
                                <p class="mb-0 font-16 d-flex align-items-center">
                                <span class="font-weight-bold w-30">Dest. Amount:</span>
                                <span class="ml-2">{{ transactionDetail.destamount }}</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr />
                <div class="transaction-raw-details-body max-width">
                    <ul class="list-unstyled mb-0"  v-for="item in this.transactionDetail.details" :key="item.id">
                        <!-- <span>{{item.transactionDate}}</span> -->
                        <span v-if="item.transactionDate">
                            <li>
                                <p class="mb-0 font-12 d-flex align-items-center">
                                    <span class="font-weight-bold w-30">Trans. ID:</span>
                                    <span class="ml-2">{{ item.transactionid }}</span>
                                </p>
                            </li>
                            <li>
                                <p class="mb-0 font-12 d-flex align-items-center">
                                    <span class="font-weight-bold w-30">Provider Name:</span>
                                    <span class="ml-2 badge-danger">{{ item.provider_name }}</span>
                                </p>
                            </li>
                            <!-- <li class="mb-2">
                                <p class="mb-0 font-12 d-flex align-items-center">
                                    <span class="font-weight-bold w-30">Trans. Status:</span>
                                    <span class="ml-2" v-if="item.status == '0'">Initial Request</span>
                                    <span class="ml-2" v-if="item.status == '1'">Callback Pending</span>
                                    <span class="ml-2" v-if="item.status == '2'">Success</span>
                                    <span class="ml-2" v-if="item.status == '3'">Failed</span>
                                    <span class="ml-2" v-if="item.status == '4'">Callback Failed</span>
                                    <span class="ml-2" v-if="item.status == '5'">Callback Success</span>
                                </p>
                            </li> -->
                            <span v-if="item.provider_name.toLowerCase() != 'terrapay' || item.terrapay_reqs.length == 0">
                                <span v-for="logs in item.status_logs" :key="logs.id">
                                    <li>
                                        <p class="mb-0 font-12 d-flex align-items-center">
                                            <span class="font-weight-bold w-30">Trans. Date:</span>
                                            <span class="ml-2">{{ convertDate(logs.requestDate) }}</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p class="mb-0 font-12 d-flex align-items-center">
                                            <span class="font-weight-bold w-30">Raw Request:</span>
                                            <span class="ml-2 scroll" v-if="logs.rawRequest">{{ logs.rawRequest }}
                                                <button @click="copyValue(logs.rawRequest)" title="copy to clipboard"><i class="fa fa-copy"></i></button>
                                            </span>
                                            <span class="ml-2" v-if="!logs.rawRequest">N/A</span>
                                        </p>
                                    </li>
                                    <li class="py-3">
                                        <p class="mb-0 font-12 d-flex align-items-center">
                                            <span class="font-weight-bold w-30">Response:</span>
                                            <span class="ml-2 scroll" :class="[logs.providerResponse.error  ? 'badge-danger' : '']" v-if="logs.providerResponse">{{ convertToString(logs.providerResponse) }}
                                                <button @click="copyValue(logs.providerResponse)" title="copy to clipboard"><i class="fa fa-copy"></i></button>
                                            </span>
                                            <span class="ml-2" v-if="!logs.providerResponse">N/A</span>
                                        </p>
                                    </li>
                                </span>
                                <!-- <li>
                                    <p class="mb-0 font-12 d-flex align-items-center">
                                        <span class="font-weight-bold w-30">Trans. Date:</span>
                                        <span class="ml-2">{{ convertDate(item.transactionDate) }}</span>
                                    </p>
                                </li>
                                <li>
                                    <p class="mb-0 font-12 d-flex align-items-center">
                                        <span class="font-weight-bold w-30">Request Date:</span>
                                        <span class="ml-2">{{ convertDate(item.createdAt) }}</span>
                                    </p>
                                </li>
                                <li>
                                    <p class="mb-0 font-12 d-flex align-items-center">
                                        <span class="font-weight-bold w-30">Raw Request:</span>
                                        <span class="ml-2 scroll" v-if="item.raw_request">{{ item.raw_request }}
                                            <button @click="copyValue(item.raw_request)" title="copy to clipboard"><i class="fa fa-copy"></i></button>
                                        </span>
                                        <span class="ml-2" v-if="!item.raw_request">N/A</span>
                                    </p>
                                </li>
                                <li>
                                    <p class="mb-0 font-12 d-flex align-items-center">
                                        <span class="font-weight-bold w-30">Response Date:</span>
                                        <span class="ml-2">{{ convertDate(item.updatedAt) }}</span>
                                    </p>
                                </li>
                                <li>
                                    <p class="mb-0 font-12 d-flex align-items-center">
                                        <span class="font-weight-bold w-30">Response:</span>
                                        <span class="ml-2 scroll" :class="[item.providerResponse.error  ? 'badge-danger' : '']" v-if="item.providerResponse">{{ convertToString(item.providerResponse) }}
                                            <button @click="copyValue(item.providerResponse)" title="copy to clipboard"><i class="fa fa-copy"></i></button>
                                        </span>
                                        <span class="ml-2" v-if="!item.providerResponse">N/A</span>
                                    </p>
                                </li> -->
                            </span>
                            <span v-if="item.provider_name.toLowerCase() == 'terrapay' && item.terrapay_reqs.length != 0">
                                <!-- <h6 v-if="item.terrapay_reqs.length == 0">Not Available</h6> -->
                                <span v-for="terrapay in item.terrapay_reqs" :key="terrapay.transaction_ref">
                                    <li>
                                        <p class="mb-0 font-12 d-flex align-items-center">
                                            <span class="font-weight-bold w-30">Trans. Date:</span>
                                            <span class="ml-2">{{ convertDate(terrapay.created_at) }}</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p class="mb-0 font-12 d-flex align-items-center">
                                            <span class="font-weight-bold w-30">Raw Request:</span>
                                            <span class="ml-2 scroll" v-if="terrapay.raw_request">{{ terrapay.raw_request }}
                                                <button @click="copyValue(terrapay.raw_request)" title="copy to clipboard"><i class="fa fa-copy"></i></button>
                                            </span>
                                            <span class="ml-2" v-if="!terrapay.raw_request">N/A</span>
                                        </p>
                                    </li>
                                    <li class="py-3">
                                        <p class="mb-0 font-12 d-flex align-items-center">
                                            <span class="font-weight-bold w-30">Response:</span>
                                            <span class="ml-2 scroll" :class="[terrapay.raw_response.error  ? 'badge-danger' : '']" v-if="terrapay.raw_response">{{ convertToString(terrapay.raw_response) }} 
                                                <button @click="copyValue(terrapay.raw_response)" title="copy to clipboard"><i class="fa fa-copy"></i></button>
                                            </span>
                                            <span class="ml-2" v-if="!terrapay.raw_response">N/A</span>
                                        </p>
                                    </li>
                                </span>
                            </span>
                            <hr />
                        </span>
                    </ul>
                </div>
            </div>
        </b-modal>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
import '@/plugins/sweetalert2'
import "@/plugins/vee-validate";
// const { VMdDateRangePicker } = require("v-md-date-range-picker")
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
const moment = require('moment')
import Multiselect from 'vue-multiselect';
import shared from "@/shared.js";

export default {
    name: 'MiddlewareTransactionListComponent',
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        // Datepicker: () => import('vuejs-datepicker'),
        // VMdDateRangePicker,
        Multiselect,
        "main-loader": LoaderComponent,
    },
    methods: {
        getSelectedDateRange(e){
            let startDate = moment(e[0]).format('D-MM-YYYY');
            let endDate = moment(e[1]).format('D-MM-YYYY');
            
            let tempArr = [];
            this.items.map((data) => { 
                let formatResponseData = moment(data.datetime.date).format('D-MM-YYYY');
                
                var d1 = startDate.split("-");
                var d2 = endDate.split("-");
                var c = formatResponseData.split("-");

                var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
                var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
                var check = new Date(c[2], parseInt(c[1])-1, c[0]);

                if(check >= from && check <= to){
                    tempArr.push(data);
                }
            });
            this.items = tempArr;
            this.totalRows = this.items.length
        },
        convertDate(data){
            return moment(data).utc().format('DD-MM-YYYY HH:mm:ss');
        },
        convertToString(data){
            console.log(data);
            return JSON.stringify(data);
        },
        async showTransactionDetail(data){
            // console.log(data);
            this.$refs.loader.show();
            try {
                let paramData = {
                    'customer_ref': data.cust_ref //'PA-64b501e0090921689584096-1' 
                }
                const response = await this.$http.get(
                        this.$apiV2transaction+'payment/transactions-details/',
                        {
                            params: paramData
                        }
                    );
                if(response.data.code == '0130-000'){
                    if(response.data.data.length > 0) {
                        const transactionDetail = response.data;

                        this.transactionDetail.accName = data.accno.acc;
                        this.transactionDetail.accNumber=data.accno.no;
                        this.transactionDetail.sendername=transactionDetail.senderFirstName + ' '+ transactionDetail.senderLastName;
                        this.transactionDetail.beneficiaryname=transactionDetail.beneficiaryFirstName + ' '+ transactionDetail.beneficiaryLastName;
                        this.transactionDetail.destamount=transactionDetail.receivingCurrency + ' '+ parseFloat(transactionDetail.destinationAmount).toFixed(2);
                        this.transactionDetail.refno=data.cust_ref;
                        this.transactionDetail.srcamount=data.srcamount;
                        this.transactionDetail.transStatus=data.status.name;

                        this.transactionDetail.details = response.data.data;
                        //this.errorMsg = '';
                        this.detailsstatus = false;
                        this.$bvModal.show('show-details');
                        this.$refs.loader.hide();
                    } else {
                        // console.log('No record found');
                        this.detailsstatus = true;
                        this.errorMsg = 'No Details Found';
                        this.$bvModal.show('show-details');
                        this.$refs.loader.hide();
                    }
                } 
            } catch (error) {
                console.log(error);
                if(error.response.data.code == 'E0130-001'){
                    this.detailsstatus = true;
                    this.errorMsg = 'There are some validation error occured. Please refer merchant backend website';
                    this.$bvModal.show('show-details');
                    this.$refs.loader.hide();
                } 
                this.$refs.loader.hide();
            }
        },

        //copy 
        copyValue(keyvalue){
            var copyVal = '';
            if(typeof keyvalue == 'object'){
                copyVal = JSON.stringify(keyvalue);
            } else {
                copyVal = keyvalue;
            }
            navigator.clipboard.writeText(copyVal);
            shared.toastrSuccess('Successfully copied');
        },

        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageJump = e.target.value
                } else {
                   this.currentPage = this.currentPageJump = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageJump = 1;
            }
        },

        // showEdit(data){
        //     console.log(data);
        //     this.$router.push({ path: '/customer/edit' })
        // }


        filterChannel() {
            console.log('selected');
        },

        handleresetFilter() {
            this.search.fname = '';
            this.search.mname = '';
            this.search.lname = '';
            this.search.mref = '';
            this.search.email = '';
            this.search.mobile = '';
            this.search.prefno = '';
            this.$refs.transactionTable.refresh();
        },

        async getTransactionList(ctx) {
            try {
                this.$refs.loader.show();
                let page = "";
                let size = "";
                let sortby = "";
                let sort = "";
                let startPage = "";
                if (ctx.currentPage != undefined) {
                    if(this.searchText){
                        startPage = 0;
                    }else{
                        page = ctx.currentPage;
                        startPage = (ctx.currentPage - 1)*ctx.perPage;
                    }
                    
                } else {
                    page = 1;
                    startPage = 0;
                }
                if (ctx.perPage != undefined) {
                    size = ctx.perPage;
                } else {
                    size = 50;
                }
                if (ctx.sortBy == "") {
                    sortby = "createdAt";
                } else {
                    sortby = ctx.sortBy;
                }
                if (ctx.sortDesc == false) {
                    sort = "d"; //"DESC";
                } else {
                    sort = "a";//"ASC";
                }

                this.currentPageJump = page;
                this.items = [];
                // // TODO

                if(this.tradenameid == '02c2b542-7da5-46ba-a271-19081650fbe7' || this.tradenameid == ''){ //For Payangel
                    let paramData = {
                        sortby: sortby,
                        ascOrDesc: sort,
                        recordPerPage: size,
                        startPage: startPage
                    };
                    // if (ctx.search) {
                    //     if (ctx.search.from) {
                    //         let startDate = moment(ctx.search.from).format('YYYY-MM-DD');
                    //         paramData.from_date = startDate;
                    //     }
                    //     if (ctx.search.to) {
                    //         let endDate = moment(ctx.search.to).format('YYYY-MM-DD');
                    //         paramData.to_date = endDate;
                    //     }
                    //     if (ctx.search.tstatus) {
                    //         const statusIndex = this.transactionStatus.indexOf(ctx.search.tstatus);
                    //         paramData.search = `status:${statusIndex}`
                    //     }
                    //     if (ctx.search.companyname) {
                    //         //
                    //     }
                    // }

                    
                    
                    if(this.searchText){
                        paramData.search = this.searchText
                    }

                    if(this.tradenameid){
                        paramData.tradenameid = this.tradenameid
                    }

                    if(this.search.fname){
                        paramData.first_name = this.search.fname
                    }
                    if(this.search.mname){
                        paramData.middle_name = this.search.mname
                    }
                    if(this.search.lname){
                        paramData.last_name = this.search.lname
                    }
                    if(this.search.mref){
                        paramData.merchant_ref_number = this.search.mref
                    }
                    if(this.search.email){
                        paramData.email = this.search.email
                    }
                    if(this.search.mobile){
                        paramData.mobile = this.search.mobile
                    }
                    if(this.search.prefno){
                        paramData.provider_reference_number = this.search.prefno
                    }

                    const response = await this.$http.get(
                        this.$payangel+'Transactions',
                        {
                            params: paramData,
                            headers: {
                                'Content-Type': 'application/json',
                                'Username': 'standappuser',
                                'Password': '49SNdxaF',
                                'ApplicationCode': 'APP004',
                                'ComChannel': 'ARCH'
                            }
                        }
                    );
                    
                    const resBody = response.data.Transaction.ResponseBody;
                    this.totalRows = resBody.total_records;
                    if(resBody.data){
                        resBody.data.map((item) => {
                            const senderfname = item.first_name ? item.first_name : '-';
                            const senderlname = item.last_name ? item.last_name : '-';
                            const destamt = item.destination_amount ? item.dest_currency + " " + parseFloat(item.destination_amount).toFixed(2) : 'N/A'; 
                            const srcamt = item.source_transfer_amount ? item.src_currency + " " + parseFloat(item.source_transfer_amount).toFixed(2) : 'N/A'; 

                            const transactionStatus = item.transaction_status;

                            let status = { name: 'N/A', class: 'badge-grey' };
                            if(transactionStatus == 'PROCESSED'){
                                status = { name: 'Beneficiary Paid', class: 'badge-success' }
                            }else if(transactionStatus == 'PENDING_CLEARANCE'){
                                status = { name: 'Awaiting Funds', class: 'badge-success' }
                            }else if(transactionStatus == 'ENTERED'){
                                status = { name: 'Pending Authorisation', class: 'badge-warning' }
                            }else if(transactionStatus == 'AGENT_OK' || transactionStatus == 'HQ_READY' || transactionStatus == 'ERROR'){
                                status = { name: 'Pending Processing', class: 'badge-danger' }
                            }else if(transactionStatus == 'HQ_OK' || transactionStatus == 'SENT_FOR_PAY' || transactionStatus == 'SENT_FOR_DELIVERY' || transactionStatus == 'HQ_OK_PAID'){
                                status = { name: 'Sent for Payment', class: 'badge-warning' }
                            }else if(transactionStatus == 'DELETED' || transactionStatus == 'ABORTED'){
                                status = { name: 'Cancelled', class: 'badge-danger' }
                            } else if(transactionStatus == 'CARD_DECLINED') {
                                status = { name: 'Card Declined', class: 'badge-danger' }
                            } else if(transactionStatus == 'ON_HOLD') {
                                status = { name: 'ON HOLD', class: 'badge-grey' }
                            } else {
                                status = { name: 'N/A', class: 'badge-grey' }
                            }
                            const custRefno = item.reference_number ? item.reference_number : '-';
                            let accType = "";
                            let accname = "";
                            let accNumber = "";
                            if (item.trans_type == "3") {  //mobile money
                                accType = "fa fa-mobile";
                                accname = item.benef_acc_name ? item.benef_acc_name : '-';
                                accNumber = item.benef_account ? item.benef_account : '-';
                            } else if (item.trans_type == "1") {   //bank
                                accType = "fa fa-bank";
                                accname = item.benef_acc_name ? item.benef_acc_name : '-';
                                accNumber = item.benef_account ? item.benef_account : '-';
                            } else if (item.trans_type == "2") {  //cash pickup
                                accType = "fa fa-money";
                                accname = item.benef_acc_name ? item.benef_acc_name : '-';
                                accNumber = item.benef_account ? item.benef_account : '-';
                            }
                            
                            // .....
                            let transactionLists = {
                                srccountry: { flagClass: "flag-icon flag-icon-" + item.country.toLowerCase() + " fi-lg fi-round" },
                                datetime: { date: moment(item.created_date).format('DD-MM-YYYY'), time: moment(item.created_date).format('h:mm A')+' GMT' },
                                sender: { name: senderfname + " " + senderlname },
                                benificiary: { name: item.benef_name },
                                accno: { type: accType, acc: accname, no: accNumber },
                                destamount: { destamount: destamt },
                                custRefno,
                                action: '',
                                status,
                                cust_ref: item.reference_number,
                                srcamount: srcamt
                            };
                            this.items.push(transactionLists);
                        });
                    } else {
                        this.items = [];
                    }
                    
                    this.$refs.loader.hide();
                    return this.items;
                } else {
                    let paramData = {
                        sorting: sort,
                        sortby: sortby,
                        pagesize: size,
                        pagenumber: page,
                    };
                    if(this.searchText){
                        paramData.search = this.searchText
                    }
                    if(this.tradenameid){
                        paramData.tradenameid = this.tradenameid
                    }

                    if(this.search.fname){
                        paramData.first_name = this.search.fname
                    }
                    if(this.search.mname){
                        paramData.middle_name = this.search.mname
                    }
                    if(this.search.lname){
                        paramData.last_name = this.search.lname
                    }
                    if(this.search.mref){
                        paramData.merchant_ref_number = this.search.mref
                    }
                    if(this.search.email){
                        paramData.email = this.search.email
                    }
                    if(this.search.mobile){
                        paramData.mobile = this.search.mobile
                    }
                    if(this.search.prefno){
                        paramData.provider_reference_number = this.search.prefno
                    }
                    // console.log(paramData);
                    const response = await this.$http.get(this.$apiV2transaction+"payment/transactions-list/",
                        {
                            params: paramData,
                            // headers: {
                            //     'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                            // }
                        }
                    );
                    // JSON responses are automatically parsed.
                    if (response.data.code == "0130-000") {
                        response.data.data.map((item) => {
                            let accType = "";
                            let accname = "";
                            let accNumber = "";
                            if (item.transferType == "Mobile") {
                                accType = "fa fa-mobile fa-lg";
                                accname = item.mobileNetwork;
                                accNumber = item.mobileNumber;
                            } else {
                                accType = "fa fa-bank fa-lg";
                                accname = item.bankName;
                                accNumber = item.bankAccountNumber;
                            }
                            const destamt = item.destinationAmount ? item.receivingCurrency + " " + parseFloat(item.destinationAmount).toFixed(2) : 'N/A';
                            // const srcamt = item.destination_amount ? item.sendingCurrency + " " + parseFloat(item.source_transfer_amount).toFixed(2) : 'N/A'; 

                            const transactionStatus = item.status;

                            let status = { name: 'N/A', class: 'badge-grey' };
                            if(transactionStatus == 0){
                                status = { name: 'Initial Request', class: 'badge-warning' }
                            }else if(transactionStatus == 1){
                                status = { name: 'Callback Pending', class: 'badge-warning' }
                            }else if(transactionStatus == 2){
                                status = { name: 'Success', class: 'badge-success' }
                            }else if(transactionStatus == 3){
                                status = { name: 'Failed', class: 'badge-danger' }
                            }else if(transactionStatus == 4){
                                status = { name: 'Callback Failed', class: 'badge-danger' }
                            }else if(transactionStatus == 5){
                                status = { name: 'Callback Success', class: 'badge-success' }
                            } else {
                                status = { name: transactionStatus, class: 'badge-grey' }
                            }

                            let transactionLists = {
                                srccountry: { flagClass: "flag-icon flag-icon-" + item.countryFrom.toLowerCase() + " fi-lg fi-round" },
                                datetime: { date: moment(item.transactionDate).format('DD-MM-YYYY'), time: moment(item.transactionDate).format('h:mm A')+' GMT' },
                                sender: { name: item.senderFirstName + " " + item.senderLastName },
                                benificiary: { name: item.beneficiaryFirstName + " " + item.beneficiaryLastName },
                                accno: { type: accType, acc: accname, no: accNumber },
                                destamount: { destamount: destamt },
                                custRefno: item.customer_ref,
                                action: '',
                                status,
                                cust_ref: item.customer_ref,
                                // srcamount: srcamt
                            };
                            this.items.push(transactionLists);
                        });
                        this.totalRows = response.data.query.total;
                    } 
                    this.$refs.loader.hide();
                    return this.items;
                }
                
            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        },

        refreshTransactionTable() {
            this.$refs.transactionTable.refresh();
        },

        clearSearch(){
            this.searchText = '';
            this.$refs.transactionTable.refresh();
        },

        async getTradename(){
            // console.log($pythonurl);
            try{
                this.tradename_options = [];
                let paramData = {
                    pagesize: 200,
                    pagenumber: 1,
                    mode : 'live'
                };
                // basepythonurl
                const response = await this.$http.get(this.$apiV2transaction+"payment/trade-name-list/",{
                                    params: paramData
                                });
                if (response.data.code == "0130-000") {
                    response.data.data.map((item) => {
                        let tradeLists = {
                            id : item.tradenameid,
                            name : item.channel_name
                        };
                        this.tradename_options.push(tradeLists);
                    });
                    // this.tradename = {id:this.tradename_options[0].id, name: this.tradename_options[0].name};
                    // this.tradenameid = this.tradename_options[0].id;
                    // this.refreshTransactionTable();
                }
            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        },
            
        setprovider(data){
            // console.log(data);
            this.tradenameid = data.id;
            this.refreshTransactionTable();
        },
    }, data() {
        return {
            dateRange: null,
            detailsstatus: false,
            errorMsg: '',
            
            presets: [
                {
                    label: 'Today',
                    range: [
                        moment().startOf('day'),
                        moment().endOf('day')
                    ]
                },
                {
                    label: 'Yesterday',
                    range: [
                        moment().subtract(1, 'day').startOf('day'),
                        moment().subtract(1, 'day').endOf('day')
                    ]
                },
                {
                    label: 'Last 7 Days',
                    range: [
                        moment().subtract(7, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Week',
                    range: [
                        moment().startOf('week'),
                        moment().endOf('week')
                    ]
                },
                {
                    label: 'Last Week',
                    range: [
                        moment().subtract(1, 'week').startOf('week'),
                        moment().subtract(1, 'week').endOf('week'),
                    ]
                },
                {
                    label: 'Last 30 Days',
                    range: [
                        moment().subtract(30, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Month',
                    range: [
                        moment().startOf('month'),
                        moment().endOf('month')
                    ]
                },
                {
                    label: 'Last Month',
                    range: [
                        moment().subtract(1,'months').startOf('month'),
                        moment().subtract(1,'months').endOf('month')
                    ]
                },
                {
                    label: 'This Year',
                    range: [
                        moment().startOf('year'),
                        moment().endOf('year')
                    ]
                },
                {
                    label: 'Last Year',
                    range: [
                        moment().subtract(1,'year').startOf('year'),
                        moment().subtract(1,'year').endOf('year'),
                    ]
                }
            ],
            transactionDetail: {
                accName:'',
                accNumber:'',
                sendername:'',
                beneficiaryname:'',
                srcamount:'',
                destamount:'',
                refno:'',
                transStatus:'',
                details:[],
            },
            
            items: [],

            fields: [
                { key: 'srccountry', label: 'S.C.', sortable: true, thClass: 'th_sortfix' },
                { key: 'datetime', label: 'Date', sortable: true, thClass: 'th_sortfix' },
                { key: 'sender', label: 'Sender', sortable: true, thClass: 'th_sortfix' },
                { key: 'benificiary', label: 'Receiver', sortable: true, thClass: 'th_sortfix' },
                { key: 'accno', label: 'Account No.', sortable: true, thClass: 'th_sortfix' },
                { key: 'custRefno', label: 'Trans. Ref', sortable: true, thClass: 'th_sortfix' },
                { key: 'destamount', label: 'Dest Amount', sortable: true, thClass: 'th_sortfix' },
                { key: 'status', label: 'Trans. Status', sortable: true, thClass: 'th_sortfix' },
                { key: 'action', label: 'Action', thClass: 'th_sortfix' },
                { sortable: true, sortByFormatted: true, filterByFormatted: true },
            ],

            totalRows: 1,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 50,
            pageOptions: [50, 100, 150, 200, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            // newMemberUrl: window.location.origin+'/members-form/members-form.html',

            isVisibleFilter: false,

            searchText:'',

            search: {
                // from: '',
                // to: '',
                // tstatus: '',
                fname: '',
                mname: '',
                lname: '',
                mref:'',
                email:'',
                mobile:'',
                prefno:'',
                // country: ''
                // type: '',
                // companyname: '',
            },

            tradename: {},
            tradename_options: [],
            tradenameid:'',

            countryOption: [
                { value: 'Australia', name:'Australia'},
                { value: 'Canada', name:'Canada'},
                { value: 'France', name:'France'},
                { value: 'Germany', name:'Germany'},
                { value: 'Ghana', name:'Ghana'},
                { value: 'India', name:'India'},
                { value: 'Italy', name:'Italy'},
                { value: 'Netherlands', name:'Netherlands'},
                { value: 'Spain', name:'Spain'},
                { value: 'Switzerland', name:'Switzerland'},
                { value: 'United Kingdom', name:'United Kingdom'},
                { value: 'United States', name:'United States'},
            ],            

            transactionStatus: ['Initial Request', 'Callback Pending', 'Success', 'Failed', 'Callback Failed', 'Callback Success'],
            typeoption: ['Customer', 'Partner'],
            channelTypes: ['a','b','c'],
        }
    }, mounted() {
        // this.totalRows = this.items.length
        if(sessionStorage.getItem("jwtToken")){
            this.getTradename();
        } else {
            this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            this.$router.push({path:'/page-login'});
        }

    }
}
</script>
<style>
text-grey {
    color: #8a8a8a !important;
}
.transaction-details-body {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
}
.transaction-raw-details-body {
    display: block;
    margin-left: 10px;
    width: 65%;
}
.transaction-raw-details-body .hide {
    display: none;
}
.transaction-lg-modal {
    max-width: 800px;
}
.scroll {
    overflow-y: scroll;
}
.max-width {
    width: 100%;
}
</style>